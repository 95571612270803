<template>
  <div
    id="store-list"
    :style="{ backgroundColor: !isAllStore ? '' : '#F5F5F5' }"
  >
    <div
      v-if="store.enterpriseHomePageItems.length != 0"
      style="
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
      "
    >
      <div id="breadcrumb" v-if="!isAllStore">
        <div class="breadcrumb-con">
          <p>您的位置：</p>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/Home">首页</el-breadcrumb-item>
            <el-breadcrumb-item
              @click.native="breadcrumbClick(list.rank)"
              v-for="list in breadList"
              :key="list.categoryId"
              style="cursor: pointer"
              >{{ list.categoryName }}</el-breadcrumb-item
            >
            <el-breadcrumb-item
              v-if="$route.query.id"
              :to="{
                path: `/Brand/RecommendedBrand?id=${$route.query.id}&brandId=${$route.query.brandId}`,
              }"
              >推荐品牌</el-breadcrumb-item
            >
            <el-breadcrumb-item
              to="/Brand/AllBrand"
              v-if="$route.query.brandName"
              >全部品牌</el-breadcrumb-item
            >
            <el-breadcrumb-item
              :to="{
                path: `/Brand/BrandGoods?brandId=${$route.query.brandId}&brandName=${$route.query.brandName}`,
              }"
              v-if="$route.query.brandName"
              >{{ $route.query.brandName }}</el-breadcrumb-item
            >
            <el-breadcrumb-item to="/AllGoods" v-if="$route.query.allGoods"
              >全部商品</el-breadcrumb-item
            >
            <el-breadcrumb-item>关联店铺</el-breadcrumb-item>
          </el-breadcrumb>
          <svg-icon icon-class="ic-close" @click="goBack" />
        </div>
      </div>
      <div class="goods-details" v-if="!isAllStore">
        <div class="top">
          <div class="commodity-img-box">
            <video
              :src="video"
              style="width: 460px; height: 460px"
              controls
              v-show="videoShow"
            ></video>
            <pic-zoom
              :url="info"
              :scale="2"
              class="commodity-img"
              v-show="!videoShow"
            ></pic-zoom>
            <div class="commodity-img-list">
              <img
                src="@/assets/images/Goods-left.png"
                class="arrow arrow-left"
                @click="leftclick"
              />
              <div>
                <ul ref="ulWidth">
                  <li class="video" v-if="video">
                    <img
                      :src="filePath[0]"
                      alt=""
                      :class="videoShow ? 'onImages' : ''"
                      @click="videoBtn"
                    />
                    <svg-icon icon-class="play" @click="videoBtn" />
                  </li>
                  <li v-for="(pic, i) in filePath" :key="i">
                    <img
                      v-lazy="pic"
                      class="commodity-img-small"
                      :class="i === imgIndex && !videoShow ? 'onImages' : ''"
                      @click="selected(pic, i)"
                      :key="i"
                    />
                  </li>
                </ul>
              </div>
              <img
                src="@/assets/images/Goods-right.png"
                class="arrow arrow-right"
                @click="rightclick"
              />
            </div>
          </div>
          <div class="right">
            <div class="title">
              <h2>
                商品名称：<span :title="CommodityDetails.commodityName">{{
                  CommodityDetails.commodityName
                }}</span>
              </h2>
              <p>
                商品条形码：<span>{{ CommodityDetails.barcode }}</span>
              </p>
            </div>
            <div class="price-box">
              <p>
                会员价：
                <span
                  >￥<i>{{
                    preferentialTime
                      ? PriceDetails.preferentialRetailPrice
                      : PriceDetails.retailPrice | Price
                  }}</i></span
                >
                <!-- <span>￥<i>104.00</i></span> -->
                <span v-if="preferentialTime" class="line_through"
                  >[<i>￥{{ PriceDetails.retailPrice | Price }}</i
                  >]</span
                >
                <!-- <span class="line_through">[<i>￥450.00</i>]</span> -->
              </p>
              <p v-if="userInfo.userEntity.userType === 'PURCHASE'">
                店长价：
                <span
                  >￥<i>{{
                    preferentialTime
                      ? PriceDetails.preferentialTradePrice
                      : PriceDetails.tradePrice | Price
                  }}</i></span
                >
                <!-- <span>￥<i>90.00</i></span> -->
                <!--  -->
                <span class="line_through" v-if="preferentialTime"
                  >[<i>￥{{ PriceDetails.tradePrice | Price }}</i
                  >]</span
                >
                <!-- <span class="line_through">[<i>￥300.00</i>]</span> -->
              </p>
              <p
                v-if="
                  userInfo.userEntity.userType === 'PURCHASE' &&
                  (PriceDetails.conditionSetting === 2 ||
                    PriceDetails.preferentialConditionSetting === 2)
                "
                class="minimum"
              >
                （订单起订量：
                <span
                  >≥{{
                    preferentialTime
                      ? PriceDetails.pTradeMinimumQuantity
                      : PriceDetails.tradeMinimumQuantity
                  }}{{ CommodityDetails.prickle }}</span
                >）
                <!-- <span>10／千克</span> -->
              </p>
              <!-- -->
              <p
                class="minimum"
                v-if="
                  userInfo.userEntity.userType === 'PURCHASE' &&
                  (PriceDetails.conditionSetting === 1 ||
                    PriceDetails.preferentialConditionSetting === 1)
                "
              >
                （订单起订价格：
                <span
                  >≥{{
                    preferentialTime
                      ? PriceDetails.ptradeStartingPrice
                      : PriceDetails.tradeStartingPrice | Price
                  }}元</span
                >）
                <!-- <span>10／千克</span> -->
              </p>
            </div>
            <div class="content">
              <div>
                <!-- CommodityDetails -->
                <p>
                  生产厂商：<span :title="CommodityDetails.manufacturer">{{
                    CommodityDetails.manufacturer
                  }}</span>
                </p>
                <p>
                  归属品牌：<span :title="CommodityDetails.brandName">{{
                    CommodityDetails.brandName
                  }}</span>
                </p>
                <!-- <p>主要成分：<span>——</span></p> -->

                <p>
                  商品重量：<span :title="CommodityDetails.weight"
                    >{{ CommodityDetails.weight }}千克</span
                  >
                </p>
                <p>
                  储藏方法：<span :title="CommodityDetails.conditions">{{
                    CommodityDetails.conditions
                  }}</span>
                </p>
                <!-- <p>特产品类：<span>——</span></p> -->
                <p>
                  保质期限：<span :title="CommodityDetails.expirationDate"
                    >{{ CommodityDetails.expirationDate }}天</span
                  >
                </p>
                <p>
                  商品规格：<span :title="CommodityDetails.specsParameter">{{
                    CommodityDetails.specsParameter
                  }}</span>
                </p>
                <p>
                  配送方式：<span>
                    {{CommodityDetails.freeShipping ? '包邮':'自提'}}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  厂商地址：<span :title="CommodityDetails.address">{{
                    CommodityDetails.address
                  }}</span>
                </p>
                <p>
                  商品产地：<span
                    :title="CommodityDetails.province + CommodityDetails.city"
                    >{{ CommodityDetails.province
                    }}{{ CommodityDetails.city }}</span
                  >
                </p>

                <!-- <p>等级：　<span>——</span></p> -->
                <p>
                  计量单位：<span :title="CommodityDetails.prickle">{{
                    CommodityDetails.prickle
                  }}</span>
                </p>
                <p>运费设置：<span>包邮</span></p>
                <p>
                  退货设置：<span
                    >{{
                      CommodityDetails.returnSetting ? "不" : ""
                    }}支持7天无理由退货</span
                  >
                </p>
                <p>
                  发货规则：<span>新疆/西藏等边远地区不发货，解释权归平台</span>
                </p>
                <!-- <p>
                商品类目：<span>{{ CommodityDetails.enView }}</span>
              </p> -->
              </div>
              <p class="td">
                商品特点：
                <span :title="CommodityDetails.characteristics">{{
                  CommodityDetails.characteristics
                    ? CommodityDetails.characteristics
                    : "无"
                }}</span>
              </p>
            </div>
            <i class="dotted-line"></i>
            <div class="view-trace-tracing" @click="goTrackBack">
              <svg-icon icon-class="eye" />
              认证查询
            </div>
          </div>
        </div>
        <p class="gl">
          <img src="@/assets/images/gl-left.png" alt="" />
          关联店铺
          <img src="@/assets/images/gl-right.png" alt="" />
        </p>
      </div>
      <div class="company" v-if="!isAllStore">
        <div>
          <img src="@/assets/images/company.png" alt="" />
          <h2>{{ store.enterpriseName }}</h2>
        </div>
        <div>
          <p>
            共<span>{{ store.total }}</span
            >个店铺<span>{{ store.sum }}</span
            >种商品
          </p>
        </div>
      </div>
      <div
        class="store-box"
        v-for="(list, i) in store.enterpriseHomePageItems"
        :key="list.storeId"
      >
        <div class="store">
          <img
            v-lazy="list.storeLogo"
            :key="list.storeLogo"
            @click="goStore(list.storeId, list.enterpriseId)"
          />
          <p @click="goStore(list.storeId, list.enterpriseId)">
            {{ list.storeName }}
          </p>
          <a @click="goStore(list.storeId, list.enterpriseId)">进入店铺首页</a>
        </div>
        <div class="goods-list">
          <!-- <img src="@/assets/images/store-list-left.png" class="left" /> -->
          <div class="goods" v-for="(item, index) in goods[i]" :key="index">
            <div
              @click="
                goGoodsDetails(item.barcode, list.enterpriseId, list.storeId)
              "
            >
              <img v-lazy="item.filePath" :key="item.filePath" />
            </div>
            <h4
              @click="
                goGoodsDetails(item.barcode, list.enterpriseId, list.storeId)
              "
              :title="item.commodityName"
            >
              {{ item.commodityName }}
            </h4>
            <p><span>￥</span>{{ Preferential(item) }}</p>
          </div>
          <!-- <img src="@/assets/images/store-list-right.png" class="right" /> -->
        </div>
      </div>
      <el-pagination
        :page-size="10"
        :pager-count="9"
        layout="prev, pager, next"
        :total="store.total"
        @prev-click="prev"
        @next-click="next"
        @current-change="current"
        v-if="isAllStore"
      >
      </el-pagination>
    </div>
    <el-empty :image-size="300" v-else></el-empty>
    <!-- </transition> -->
  </div>
</template>

<script>
import PicZoom from "vue-piczoom";
import { getclassificationArray, preferential } from "@/utils/validate";
import Breadcrumb from "@/components/Breadcrumb";
import { mapState } from "vuex";

export default {
  name: "store-list",
  components: {
    PicZoom,
    Breadcrumb,
  },
  computed: {
    ...mapState("personalUser", ["userInfo"]),
    Preferential() {
      return function (val) {
        if (this.userInfo.userEntity.userType === "PURCHASE") {
          return preferential(val, "tradePrice");
        } else {
          return preferential(val, "retailPrice");
        }
      };
    },
    breadList() {
      let breadList = [];
      if (this.$route.query.breadcrumb) {
        let category = JSON.parse(localStorage.getItem("category"));
        this.categoryNum = JSON.parse(this.$route.query.breadcrumb);
        let index = this.categoryNum;
        breadList.push(category[index[0]]);
        let arr = getclassificationArray(category[index[0]]);
        if (index[1] != undefined) {
          breadList.push(breadList[0].subItemList[index[1]]);
          arr = getclassificationArray(breadList[0].subItemList[index[1]]);
        }
        if (index[2] != undefined) {
          breadList.push(
            breadList[0].subItemList[index[1]].subItemList[index[2]]
          );
          arr = getclassificationArray(
            breadList[0].subItemList[index[1]].subItemList[index[2]]
          );
        }
        this.category = arr;
      }
      return breadList;
    },
  },
  data() {
    return {
      store: { enterpriseHomePageItems: [] },
      pageNum: 1, //页码
      goods: [],
      isAllStore: true,
      info: "",
      CommodityDetails: "", //商品详情
      filePath: "", //商品图片详情
      imgIndex: 0, //选择商品图片
      PriceDetails: "", //商品价格详情
      preferentialTime: false, //是否优惠
      ulW: 0,
      videoShow: false,
      video: "",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getEnterprise();
      },
    },
  },
  filters: {
    Price(p) {
      if (p === undefined || p === null) {
        return "-";
      } else {
        if (String(Number(p)).indexOf(".")) {
          return p.toFixed(2);
        } else {
          return p;
        }
      }
    },
  },
  created() {
    this.getEnterprise();
    this.$route.query.enterpriseId && (this.isAllStore = false);

    if (this.$route.query.barcode != undefined) {
      this.getDetails();
    }
    // console.log(document.querySelector(".mouse-cover-canvas"));
    // document.querySelector(".mouse-cover-canvas").style.top = "0";
    // document.getElementsByClassName("mouse-cover-canvas")[0].style.top = "0";
  },
  mounted() {
    if (this._isMobile()) {
      // 移动
      window.location.href = `http://sgmall.origmall.cn/#/GoodsDetails?&enterpriseId=${this.$route.query.enterpriseId}&barcode=${this.$route.query.barcode}`;
    } else {
      // pc
    }

    // this.$nextTick(function () {
    //   console.log(document.querySelector(".mouse-cover-canvas"));
    //   document.querySelector(".mouse-cover-canvas").className = "0";
    // });
    // if (location.href.indexOf("#reloaded") == -1) {
    //   location.href = location.href + "#reloaded";
    //   location.reload();
    // }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  methods: {
    //获取店铺信息
    async getEnterprise() {
      const data = await this.$API.homePage.enterprise({
        object: {
          barcode: this.$route.query.barcode,
          commodityCode: "",
          enterpriseId: this.$route.query.enterpriseId,
          like: this.$route.query.like,
        },
        pageNum: this.pageNum,
        pageSize: 10,
      });
      this.store = data.data.data;
      let goods = this.store.enterpriseHomePageItems.map((item) => {
        return {
          goods: item.storeCommodityItems,
        };
      });
      this.goods = goods.map((a, b, c) => {
        return a.goods.map((d) => {
          return {
            ...d,
            filePath: d.filePath.split(",")[0].endsWith(".mp4")
              ? d.filePath.split(",")[1]
              : d.filePath.split(",")[0],
          };
        });
      });
      this.PriceDetails =
        this.store.enterpriseHomePageItems[0].storeCommodityItems[0];
      let now = new Date();
      let m = now.getTime();
      if (this.PriceDetails.preferentialStartTime == null) {
        this.preferentialTime = false;
        return;
      }
      if (
        this.PriceDetails.preferentialStartTime < m &&
        m < this.PriceDetails.preferentialEndTime
      ) {
        this.preferentialTime = true;
      } else {
        this.preferentialTime = false;
      }
    },
    //去店铺首页
    goStore(id, enterpriseId) {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${id}`,
        query: { enterpriseId: enterpriseId },
      });

      window.open(a.href, "_blank");
    },
    //去商品详情
    goGoodsDetails(barcode, enterpriseId, storeId) {
      console.log(barcode, enterpriseId, storeId);
      let a = this.$router.resolve({
        path: `/Store/HomePage/${storeId}/GoodsDetails/${barcode}`,
        query: {
          enterpriseId: enterpriseId,
          breadcrumb: this.$route.query.breadcrumb,
          id: this.$route.query.id,
          brandId: this.$route.query.brandId,
          brandName: this.$route.query.brandName,
          allGoods: this.$route.query.allGoods,
        },
      });
      window.open(a.href, "_blank");
    },
    //获取商品详情
    async getDetails() {
      const data = await this.$API.store.getCommodityDetails({
        object: {
          barcode: this.$route.query.barcode,
          enterpriseId: this.$route.query.enterpriseId,
        },
      });
      this.CommodityDetails = data.data.data;
      // this.filePath = data.data.data.filePath.split(",").map((pic, i) => {
      //   return i === 0 ? pic : "http://hwpicture.realbrand.net/" + pic;
      // });
      this.filePath = data.data.data.filePath
        .split(",")
        .filter((item) => !item.endsWith(".mp4"));
      this.info = this.filePath[0];
      this.video = data.data.data.filePath
        .split(",")
        .filter((item) => item.endsWith(".mp4"))[0];
      if (this.video) this.videoShow = true;

      this.$nextTick(function () {
        this.index = this.$refs.ulWidth.children.length - 5;
      });
    },
    //上一页
    prev() {
      this.pageNum--;
      this.getEnterprise();
      this.backTop();
    },
    //下一页
    next() {
      this.pageNum++;
      this.getEnterprise();
      this.backTop();
    },
    //分页数字
    current(i) {
      this.pageNum = i;
      this.getEnterprise();
      this.backTop();
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    backTop() {
      //点击回顶部
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    //放大镜
    selected(src, i) {
      this.info = src;
      this.imgIndex = i;
      this.videoShow = false;
    },
    videoBtn() {
      this.videoShow = true;
    },
    rightclick() {
      if (this.index > 0) {
        this.index--;
        this.ulW += -76;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },
    leftclick() {
      if (this.index < this.$refs.ulWidth.children.length - 5) {
        this.index++;
        this.ulW += 76;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },

    //面包屑点击
    breadcrumbClick(i) {
      let oldVal = this.categoryNum;
      oldVal.splice(i);
      let newVal = oldVal;
      this.$router.push({
        name: "category-list",
        query: {
          breadcrumb: JSON.stringify(newVal),
        },
      });
    },
    goBack() {
      if (this.$route.query.breadcrumb) {
        this.$router.push({
          name: "category-list",
          query: {
            breadcrumb: this.$route.query.breadcrumb,
          },
        });
      } else if (this.$route.query.id) {
        this.$router.push({
          name: "recommended-brand",
          query: {
            id: this.$route.query.id,
            brandId: this.$route.query.brandId,
          },
        });
      } else if (this.$route.query.brandName) {
        this.$router.push({
          name: "brand-goods",
          query: {
            brandId: this.$route.query.brandId,
            brandName: this.$route.query.brandName,
          },
        });
      } else if (this.$route.query.allGoods) {
        this.$router.push("/Allgoods");
      } else {
        this.$router.push("/Home");
      }
    },
    goTrackBack() {
      // console.log(this.$route);
      this.$router.push({
        name: "track-back",
        query: {
          id: this.$route.query.barcode,
          enterpriseId: this.$route.query.enterpriseId,
        },
      });
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
#store-list {
  border-top: 1px solid var(--subjectColor);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding-bottom: 40px;
  .el-empty {
    padding: 100px 0;
  }
  #breadcrumb {
    background: #f8f8f8;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    .breadcrumb-con {
      width: 1200px;
      height: 55px;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: #999;
      }
      ::v-deep.el-breadcrumb {
        span {
          color: #999;
        }
        .el-breadcrumb__inner {
          font-weight: normal;
        }
        .el-breadcrumb__inner:hover {
          color: var(--subjectColor);
        }
      }
      ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
        color: #333;
        cursor: default;
      }
      .svg-icon {
        margin-left: 4px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
      .svg-icon:hover {
        color: #000;
      }
    }
  }

  .goods-details {
    .gl {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      img {
        margin: 0 20px;
      }
    }
    .top {
      width: 1200px;
      margin: 24px 0 40px 0;
      display: flex;
      .commodity-img-box {
        display: flex;
        flex-direction: column;
        video {
          border: 1px solid #eee;
        }
        ::v-deep .magnifier-box {
          border: 1px solid #eee;
          img {
            // width: auto;
            // height: auto;
            width: 100%;
            height: 100%;
          }
        }
        .commodity-img {
          width: 460px;
          height: 460px;
          img {
            width: 460px;
            height: 460px;
          }
        }

        .commodity-img-list {
          margin-top: 14px;
          width: 460px;
          position: relative;
          div {
            width: 384px;
            overflow: hidden;
            position: relative;
            height: 60px;
            margin-left: 30px;
            ul {
              position: absolute;
              left: 0;
              transition: all 0.5s;
              .video {
                position: relative;
                img {
                  width: 60px;
                  height: 60px;
                  cursor: pointer;
                  border: 2px solid #fff;
                }
                .svg-icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  font-size: 20px;
                  margin-top: -10px;
                  margin-left: -10px;
                  color: #999;
                  cursor: pointer;
                }
              }
              li {
                float: left;
                margin-left: 16px;

                .commodity-img-small {
                  width: 60px;
                  height: 60px;
                  border: 2px solid #fff;
                  cursor: pointer;
                }
                .onImages {
                  border: 2px solid var(--subjectColor);
                }
              }
            }
          }
          .arrow {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 15px;
            cursor: pointer;
          }
          .arrow-left {
            left: 0;
          }
          .arrow-right {
            right: 0;
          }
        }
      }
      .right {
        // width: 560px;
        height: 460px;
        margin-left: 24px;
        position: relative;
        .title {
          display: flex;
          line-height: 26px;
          color: #333333;
          justify-content: space-between;
          h2 {
            font-size: 20px;
            color: #888888;
            width: 520px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              color: #333333;
            }
          }
          p {
            font-size: 14px;
            color: #999999;
            flex-shrink: 0;
            span {
              color: var(--subjectColor);
            }
          }
        }
        .price-box {
          width: 716px;
          height: 68px;
          margin: 16px 0;
          display: flex;
          align-items: center;
          background: url("../../assets/images/price-bg.png");
          p {
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            height: 20px;
            span:first-child {
              color: #ec382f;
              i {
                font-size: 22px;
              }
            }
            .line_through {
              margin-left: 4px;
              i {
                text-decoration: line-through;
              }
            }
          }
          p:first-child {
            width: 342px;
            margin-left: 8px;
          }
          p:nth-child(2) {
            margin-right: 2px;
            flex-shrink: 0;
          }
          .minimum {
            line-height: 16px;
            height: 16px;
            flex-shrink: 0;

            span {
              color: var(--subjectColor);
            }
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          .td {
            font-size: 14px;
            color: #888888;
            display: flex;
            line-height: 20px;
            margin-bottom: 10px;
            span {
              width: 640px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          div {
            flex: 1;
            p {
              font-size: 14px;
              color: #888888;
              display: flex;
              line-height: 20px;
              margin-bottom: 10px;
              span {
                width: 272px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .dotted-line {
          width: 100%;
          border-top: 1px dashed #ccc;
          display: block;
          margin: 6px 0 20px 0;
        }
        .buy {
          height: 36px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            display: flex;
            align-items: center;
            .el-input-number {
              line-height: 36px;
              width: 160px;
              height: 36px;
              margin-right: 20px;
              ::v-deep.el-input-number__decrease {
                width: 36px;
                height: 34px;
                top: 2px;
                line-height: 34px;
                color: #333;
                .el-icon-minus:before {
                  font-weight: 900;
                }
              }
              .el-input-number__decrease:hover {
                color: #333;
              }
              ::v-deep.el-input-number__increase {
                width: 36px;
                height: 34px;
                top: 2px;
                line-height: 34px;
                color: #333;
                .el-icon-plus:before {
                  font-weight: 900;
                }
              }
              .el-input-number__increase:hover {
                color: #333;
              }
              ::v-deep.el-input__inner {
                border-radius: 0;
                height: 36px;
                border-color: #ddd;
              }
              ::v-deep.el-input__inner:focus {
                border-color: none;
              }
            }
            p:last-child {
              color: #999999;
              i {
                color: var(--subjectColor);
                margin-right: 4px;
              }
            }
          }

          p:last-child {
            color: #999999;
            span {
              color: var(--subjectColor);
            }
          }
        }
        ::v-deep .el-dialog__wrapper {
          .el-dialog__body {
            text-align: center;
            font-size: 16px;
          }
          .el-dialog__footer {
            .el-button--primary {
              background: var(--subjectColor);
              border: var(--subjectColor) 1px solid;
            }
            .el-button--default:hover {
              color: var(--subjectColor);
              background: rgba($color: #fff, $alpha: 0.2);
              border: rgba($color: var(--subjectColor), $alpha: 0.2) 1px solid;
            }
          }
        }
        .add-to-shopping-cart {
          width: 148px;
          height: 42px;
          background: var(--subjectColor);
          border-radius: 4px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 42px;
          position: absolute;
          bottom: 0px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
        .view-trace-tracing {
          width: 148px;
          height: 42px;

          border-radius: 4px;
          font-size: 16px;
          color: var(--subjectColor);
          text-align: center;
          border: 1px solid var(--subjectColor);
          line-height: 40px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
      }
    }
  }
  .company {
    width: 1200px;
    height: 60px;
    display: flex;

    margin-top: 30px;
    background: #f7f7f7;
    justify-content: space-between;
    img {
      width: 60px;
      height: 60px;
    }
    div {
      display: flex;
      height: 100%;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        margin: 0 10px 0 18px;
      }
      h2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #333333;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        margin-right: 20px;
        span {
          color: #fc2c22;
        }
        span:nth-child(2) {
          margin-left: 8px;
        }
      }
    }
  }
  .store-box {
    background: #fff;
    margin-top: 20px;
    border: 1px solid #ddd;
    display: flex;
    .store {
      width: 200px;
      height: 224px;
      border-right: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 88px;
        height: 88px;
        margin: 20px 0 10px 0;
        cursor: pointer;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        cursor: pointer;
      }
      a {
        width: 96px;
        height: 28px;
        background: var(--subjectColor);
        border-radius: 2px;
        font-size: 12px;
        line-height: 28px;
        color: #ffffff;
        text-align: center;
        margin-top: 34px;
        cursor: pointer;
      }
    }
    .goods-list:hover {
      .left,
      .right {
        display: block;
      }
    }
    .goods-list {
      height: 224px;
      width: 1000px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      padding: 0 8px;

      .left,
      .right {
        position: absolute;
        top: 68px;
        display: none;
        cursor: pointer;
      }
      .left {
        left: 0;
      }
      .right {
        right: 0;
      }
      .goods {
        width: 164px;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          width: 140px;
          height: 140px;
          overflow: hidden;
          margin-top: 20px;
          cursor: pointer;
          img {
            width: 140px;
            height: 140px;
            transition: all 0.5s;
          }
          img:hover {
            transform: scale(1.08);
          }
        }
        h4 {
          width: 140px;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 10px 0 2px 0;
          cursor: pointer;
          text-align: center;
        }
        h4:hover {
          color: var(--subjectColor);
        }
        p {
          line-height: 22px;
          font-size: 16px;
          color: #f34546;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    ::v-deep button:hover {
      color: var(--subjectColor);
    }
    ::v-deep button {
      width: 34px;
      height: 34px;
      border: 1px solid #ddd;
      padding: 0;
      min-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 4px;
      i {
        margin-left: 1px;
      }
    }
    ::v-deep .el-pager {
      li {
        width: 34px;
        height: 34px;
        padding: 0;
        border: 1px solid #ddd;
        min-width: 0;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        margin: 0 4px;
      }
      li.active {
        color: var(--subjectColor);
        border-color: var(--subjectColor);
      }
      .more {
        border: none;
        margin: 0;
        width: 26px;
      }
    }
  }
}
</style>
<style lang="scss">
.mouse-cover-canvas {
  top: 278px !important;
}
</style>